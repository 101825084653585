const navigation = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Business English Management",
    children: [
      {
        _tag: "CSidebarNavItem",
        name: "Business English Course",
        to: "/business-course",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Business English User",
        to: "/business-member",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Course",
    to: "/course",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Lecture",
    to: "/lecture",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Question",
    to: "/question",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Book Recommend",
    to: "/book",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Diagnosis Result",
    to: "/diagnosis",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Configure",
    to: "/config",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Notification",
    to: "/notification",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Employee",
    to: "/employee",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/member",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Inquiry",
    to: `/inquiry`,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Deep Link",
    to: "/deep_link",
  },

  {
    _tag: "CSidebarNavItem",
    name: "Question Web",
    to: "/question_web",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Keys",
    to: "/keys",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Links",
    to: "/links",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Promotional Codes",
    to: "/promotional-codes",
  },
  {
    _tag: "CSidebarNavDivider",
  },
  {
    _tag: "CSidebarNavDivider",
    className: "m-2",
  },
];
export default navigation;
