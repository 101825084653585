import CIcon from "@coreui/icons-react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav
} from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import icon from "../assets/img/photo_2021-04-14_14-36-13.jpg";

// sidebar nav config
import { set } from "src/toolkit/responsive";
import Nav from "./_nav";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);

  return (
    <CSidebar show={show} onShowChange={(val) => dispatch(set(val))}>
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon className="c-sidebar-brand-full" src={icon} height={35} />
        <CIcon className="c-sidebar-brand-minimized" src={icon} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <Nav />
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
