import { CSidebarNavDropdown, CSidebarNavItem } from "@coreui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import NavAdmin from "./nav-admin";
import NavSuper from "./nav-superadmin";

function Nav() {
  const location = useLocation();
  const admin = JSON.parse(localStorage.getItem("user"));
  const [dropdownStates, setDropdownStates] = useState({});
  const navItems = admin.type === "SUPERADMIN" ? NavSuper : NavAdmin;

  useEffect(() => {
    const isOpenStates = {};

    navItems.forEach((item) => {
      if (item._tag === "CSidebarNavDropdown" && item.children) {
        const isOpen = item.children.some(
          (child) => location.pathname === child.to
        );
        isOpenStates[item.name] = isOpen;
      }
    });

    setDropdownStates(isOpenStates);
  }, [location.pathname]);

  return (
    <div>
      {navItems.map((item, index) => {
        const { _tag, children, ...rest } = item;
        switch (_tag) {
          case "CSidebarNavDropdown":
            return (
              <CSidebarNavDropdown
                {...rest}
                key={index}
                show={dropdownStates[item.name]}
              >
                {children.map((child, childIndex) => (
                  <CSidebarNavItem {...child} key={childIndex} />
                ))}
              </CSidebarNavDropdown>
            );
          case "CSidebarNavItem":
            return <CSidebarNavItem {...rest} key={index} />;
          default:
            return null;
        }
      })}
    </div>
  );
}

export default Nav;
