import React from "react";

const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard"));
const Course = React.lazy(() => import("./views/Course/CourseList"));
const CourseAdd = React.lazy(() => import("./views/Course/AddCourse"));
const CourseEdit = React.lazy(() => import("./views/Course/EditCourse"));

const BusinessCourse = React.lazy(() =>
  import("./views/BusinessCourse/CourseList")
);
const BusinessCourseAdd = React.lazy(() =>
  import("./views/BusinessCourse/AddCourse")
);

const MobileAppConfig = React.lazy(() =>
  import("./views/Dashboard/MobileAppConfig")
);

const ListEmployee = React.lazy(() => import("./views/Employee/EmployeeList"));
const CreateEmployee = React.lazy(() =>
  import("./views/Employee/CreateEmployee")
);
const UpdateEmployee = React.lazy(() =>
  import("./views/Employee/UpdateEmployee")
);

const ListKeys = React.lazy(() => import("./views/Keys/KeyList"));
const ListKeyHistories = React.lazy(() => import("./views/Keys/KeyHistories"));
const CreateKey = React.lazy(() => import("./views/Keys/KeyCRUD"));
const CreateLinks = React.lazy(() => import("./views/Links/LinksCRUD"));

const Lecture = React.lazy(() => import("./views/Lecture/LectureList"));
const CreateLecture = React.lazy(() => import("./views/Lecture/CreateLecture"));
const UpdateLecture = React.lazy(() => import("./views/Lecture/UpdateLecture"));

const BusinessLecture = React.lazy(() => import("./views/BusinessLecture/LectureList"));
const BusinessCreateLecture = React.lazy(() => import("./views/BusinessLecture/CreateLecture"));
const BusinessUpdateLecture = React.lazy(() => import("./views/BusinessLecture/UpdateLecture"));

const ListMember = React.lazy(() => import("./views/Member/MemberList"));
const CreateMember = React.lazy(() => import("./views/Member/CreateMember"));
const UpdateMember = React.lazy(() => import("./views/Member/UpdateMember"));

const ListBusinessMember = React.lazy(() =>
  import("./views/BusinessMember/MemberList")
);
const CreateBusinessMember = React.lazy(() =>
  import("./views/BusinessMember/CreateMember")
);
const UpdateBusinessMember = React.lazy(() =>
  import("./views/BusinessMember/UpdateMember")
);

const TestLectureList = React.lazy(() =>
  import("./views/TestLecture/TestLectureList")
);
const CreateTestLecture = React.lazy(() =>
  import("./views/TestLecture/CreateTestLecture")
);
const UpdateTestLecture = React.lazy(() =>
  import("./views/TestLecture/UpdateTestLecture")
);

const QuestionList = React.lazy(() => import("./views/Question/QuestionList"));
const CreateQuestion = React.lazy(() =>
  import("./views/Question/CreateQuestion/CreateQuestion")
);
const UpdateQuestion = React.lazy(() =>
  import("./views/Question/UpdateQuestion/UpdateQuestion")
);

const BusinessQuestionList = React.lazy(() =>
  import("./views/BusinessQuestion/QuestionList")
);
const CreateBusinessQuestion = React.lazy(() =>
  import("./views/BusinessQuestion/CreateQuestion/CreateQuestion")
);
const UpdateBusinessQuestion = React.lazy(() =>
  import("./views/BusinessQuestion/UpdateQuestion/UpdateQuestion")
);

const BookList = React.lazy(() => import("./views/Book/BookList"));
const CreateBook = React.lazy(() => import("./views/Book/CreateBook"));
const UpdateBook = React.lazy(() => import("./views/Book/UpdateBook"));

const DiagnosisList = React.lazy(() =>
  import("./views/Diagnosis/DiagnosisList")
);
const CreateDiagnosis = React.lazy(() =>
  import("./views/Diagnosis/CreateDiagnosis")
);
const UpdateDiagnosis = React.lazy(() =>
  import("./views/Diagnosis/UpdateDiagnosis")
);

const ConfigList = React.lazy(() => import("./views/Config/ConfigList"));
const CreateConfig = React.lazy(() => import("./views/Config/CreateConfig"));
const UpdateConfig = React.lazy(() => import("./views/Config/UpdateConfig"));

const NotificationList = React.lazy(() =>
  import("./views/Notification/NotificationList")
);
const CreateNotification = React.lazy(() =>
  import("./views/Notification/CreateNotification")
);
const UpdateNotification = React.lazy(() =>
  import("./views/Notification/UpdateNotification")
);

const Inquiry = React.lazy(() => import("./views/Inquiry/inquiryList"));
const InquiryAnswer = React.lazy(() => import("./views/Inquiry/InquiryAnswer"));

const FavoriteList = React.lazy(() => import("./views/Favorite/FavoriteList"));

const DeepLinkList = React.lazy(() => import("./views/DeepLink/DeepLinkList"));
const CreateDeepLink = React.lazy(() =>
  import("./views/DeepLink/CreateDeepLink")
);
const UpdateDeepLink = React.lazy(() =>
  import("./views/DeepLink/UpdateDeepLink")
);

const QuestionWebList = React.lazy(() =>
  import("./views/QuestionWeb/QuestionWebList")
);
const CreateQuestionWeb = React.lazy(() =>
  import("./views/QuestionWeb/CreateQuestionWeb")
);
const UpdateQuestionWeb = React.lazy(() =>
  import("./views/QuestionWeb/UpdateQuestionWeb")
);
const CreateQuestionCustom = React.lazy(() =>
  import("./views/Question/CreateQuestion/CreateQuestionCustom")
);
const PromotionalCodesList = React.lazy(() =>
  import("./views/PromotionalCodes/PromotionalCodesList")
);
const CreatePromotionalCodes = React.lazy(() =>
  import("./views/PromotionalCodes/CreatePromotionalCodes")
);

const routes = [
  { path: "/", exact: true, name: "Dashboard", component: Dashboard },

  {
    path: "/business-course",
    name: "Business English Course",
    component: BusinessCourse,
    exact: true,
  },
  {
    path: "/business-course/add",
    name: "Create",
    component: BusinessCourseAdd,
  },
  {
    path: "/business-course/edit/:id",
    name: "Edit",
    component: CourseEdit,
  },

  {
    path: "/business-member",
    name: "Business English User",
    component: ListBusinessMember,
    exact: true,
  },
  {
    path: "/business-member/create",
    name: "Create",
    component: CreateBusinessMember,
  },
  {
    path: "/business-member/update/:id",
    name: "Update",
    component: UpdateBusinessMember,
  },

  { path: "/member", name: "User List", component: ListMember, exact: true },
  { path: "/member/create", name: "Create", component: CreateMember },
  { path: "/member/update/:id", name: "Update", component: UpdateMember },

  { path: "/lecture", name: "Lecture", component: Lecture, exact: true },
  { path: "/lecture/create", name: "Create", component: CreateLecture },
  { path: "/lecture/update/:id", name: "Update", component: UpdateLecture },

  {
    path: "/business-lecture",
    name: "Business English Lecture",
    component: BusinessLecture,
    exact: true,
  },
  {
    path: "/business-lecture/create",
    name: "Create",
    component: BusinessCreateLecture,
  },
  {
    path: "/business-lecture/update/:id",
    name: "Update",
    component: BusinessUpdateLecture,
  },

  {
    path: "/test-lecture",
    name: "Test Lecture",
    component: TestLectureList,
    exact: true,
  },
  { path: "/test-lecture/add", name: "Create", component: CreateTestLecture },
  { path: "/test-lecture/:id", name: "Edit", component: UpdateTestLecture },

  { path: "/question", name: "Question", component: QuestionList, exact: true },
  { path: "/question/add", name: "Create", component: CreateQuestion },
  {
    path: "/question/add-custom",
    name: "Create",
    component: CreateQuestionCustom,
  },
  { path: "/question/:id", name: "Edit", component: UpdateQuestion },

  {
    path: "/business-question",
    name: "Business English Question",
    component: BusinessQuestionList,
    exact: true,
  },
  {
    path: "/business-question/add",
    name: "Create",
    component: CreateBusinessQuestion,
  },
  {
    path: "/business-question/:id",
    name: "Edit",
    component: UpdateBusinessQuestion,
  },

  { path: "/course", name: "Course", component: Course, exact: true },
  { path: "/course/add", name: "Create", component: CourseAdd },
  { path: "/course/edit/:id", name: "Edit", component: CourseEdit },

  {
    path: "/app-config",
    name: "App Config",
    component: MobileAppConfig,
    exact: true,
  },
  { path: "/app-config/add", name: "Create", component: CreateEmployee },
  { path: "/app-config/:id", name: "Edit", component: UpdateEmployee },

  { path: "/employee", name: "Employee", component: ListEmployee, exact: true },
  { path: "/employee/create", name: "Create", component: CreateEmployee },
  { path: "/employee/:id", name: "Edit", component: UpdateEmployee },

  { path: "/keys", name: "Keys", component: ListKeys, exact: true },
  {
    path: "/historykeys/:id",
    name: "KeyHistories",
    component: ListKeyHistories,
    exact: true,
  },
  { path: "/keys/create", name: "Create", component: CreateKey },
  { path: "/keys/:id", name: "Edit", component: CreateKey },

  {
    path: "/diagnosis",
    name: "Diagnosis",
    component: DiagnosisList,
    exact: true,
  },
  { path: "/diagnosis/create", name: "Create", component: CreateDiagnosis },
  { path: "/diagnosis/:id", name: "Edit", component: UpdateDiagnosis },

  { path: "/config", name: "Config", component: ConfigList, exact: true },
  { path: "/config/create", name: "Create", component: CreateConfig },
  { path: "/config/:id", name: "Edit", component: UpdateConfig },

  { path: "/book", name: "Book", component: BookList, exact: true },
  { path: "/book/create", name: "Create", component: CreateBook },
  { path: "/book/update/:id", name: "Edit", component: UpdateBook },

  {
    path: "/notification",
    name: "Notification",
    component: NotificationList,
    exact: true,
  },
  { path: "/notification/add", name: "Create", component: CreateNotification },
  { path: "/notification/:id", name: "Edit", component: UpdateNotification },

  { path: "/inquiry", name: "Inquiry", component: Inquiry, exact: true },
  { path: "/inquiry/answer/:id", name: "answer", component: InquiryAnswer },
  { path: "/inquiry/send/:id", name: "send", component: InquiryAnswer },

  { path: "/favorite", name: "Favorite", component: FavoriteList, exact: true },
  {
    path: "/favorite:id",
    name: "Favorite",
    component: FavoriteList,
    exact: true,
  },

  {
    path: "/deep_link",
    name: "Deep Link",
    component: DeepLinkList,
    exact: true,
  },
  { path: "/deep_link/create", name: "Create", component: CreateDeepLink },
  { path: "/links", name: "Create", component: CreateLinks },
  { path: "/deep_link/:id", name: "Edit", component: UpdateDeepLink },

  {
    path: "/question_web",
    name: "Question Web",
    component: QuestionWebList,
    exact: true,
  },
  {
    path: "/question_web/create",
    name: "Create",
    component: CreateQuestionWeb,
  },
  { path: "/question_web/:id", name: "Edit", component: UpdateQuestionWeb },
  {
    path: "/promotional-codes",
    name: "Promotional Codes",
    component: PromotionalCodesList,
    exact: true,
  },
  {
    path: "/promotional-codes/create",
    name: "Create",
    component: CreatePromotionalCodes,
  },
];

export default routes;
