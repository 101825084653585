import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { TheLayout } from "./Containers";
import memberApi from "./api/memberApi";
import questionWebApi from "./api/questionWebApi";
import { icons } from "./assets/icons";
import "./scss/style.scss";
import { setTotalQuestion } from "./toolkit/question";
React.icons = icons;
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ResetPassword = React.lazy(() => import("./views/pages/resetPassword/ResetPassword"));
const Ads = React.lazy(() => import("src/Containers/Ads"));
const Question = React.lazy(() => import("./views/pages/question/Question"));
const Result = React.lazy(() => import("./views/pages/result/Result"));
const Maintenance = React.lazy(() =>
  import("./views/pages/maintenance/Maintenance")
);

function App() {
  const dispatch = useDispatch();

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
  useEffect(() => {
    const fetch = async () => {
      const response2 = await questionWebApi.countQuestion(1, 99999);
      if (response2.code === 200) {
        dispatch(setTotalQuestion(response2.results.objects.count));
      }
    };
    fetch();
  }, []);

  useEffect(async () => {
    if (localStorage.getItem("user")) {
      try {
        const id = "dfbc9fd0-c40a-11eb-8921-cd321b8e7d13";
        const token = JSON.parse(localStorage.getItem("token"));
        const res = await memberApi.findOne(id, token);
      } catch (error) {
        localStorage.clear();
      }
    }
  }, []);

  return (
    <div>
      <HashRouter>
        <Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login" component={Login} />
            <Route exact path="/reset-password" name="Reset Password" component={ResetPassword} />
            <Route
              exact
              path="/question_test"
              name="Question"
              component={Question}
            />
            <Route exact path="/result" name="Result" component={Result} />
            <Route exact path="/ads" name="Ads" component={Ads} />
            <Route
              exact
              path="/maintenance"
              name="Maintenance"
              component={Maintenance}
            />

            <PrivateRoute exact path="/" component={TheLayout} />

            <PrivateRoute exact path="/employee" component={TheLayout} />
            <PrivateRoute exact path="/employee/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/employee/:id/update"
              component={TheLayout}
            />

            <PrivateRoute exact path="/lecture" component={TheLayout} />
            <PrivateRoute exact path="/lecture/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/lecture/update/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/business-lecture"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/business-lecture/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/business-lecture/update/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/lecture/question/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/lecture/questionCreate/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/lecture/questionUpdate/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/diagnosis" component={TheLayout} />
            <PrivateRoute
              exact
              path="/diagnosis/create"
              component={TheLayout}
            />
            <PrivateRoute exact path="/diagnosis/:id" component={TheLayout} />

            <PrivateRoute exact path="/book" component={TheLayout} />
            <PrivateRoute exact path="/book/create" component={TheLayout} />
            <PrivateRoute exact path="/book/update/:id" component={TheLayout} />

            <PrivateRoute exact path="/course" component={TheLayout} />
            <PrivateRoute exact path="/course/:id/list" component={TheLayout} />
            <PrivateRoute exact path="/course/add" component={TheLayout} />
            <PrivateRoute
              exact
              path="/course/:id/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/course/:id/update"
              component={TheLayout}
            />
            <PrivateRoute exact path="/course/edit/:id" component={TheLayout} />
            <PrivateRoute exact path="/links" component={TheLayout} />
            <PrivateRoute exact path="/business-course" component={TheLayout} />
            <PrivateRoute
              exact
              path="/business-course/add"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/business-course/edit/:id"
              component={TheLayout}
            />
            <PrivateRoute exact path="/business-member" component={TheLayout} />
            <PrivateRoute
              exact
              path="/business-member/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/business-member/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/member" component={TheLayout} />
            <PrivateRoute exact path="/member/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/member/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/test-lecture" component={TheLayout} />
            <PrivateRoute
              exact
              path="/test-lecture/add"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/test-lecture/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/question" component={TheLayout} />
            <PrivateRoute exact path="/question/add" component={TheLayout} />
            <PrivateRoute exact path="/question/:id" component={TheLayout} />
            <PrivateRoute
              exact
              path="/business-question"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/business-question/add"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/business-question/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/config" component={TheLayout} />
            <PrivateRoute exact path="/config/create" component={TheLayout} />
            <PrivateRoute exact path="/config/:id" component={TheLayout} />

            <PrivateRoute exact path="/notification" component={TheLayout} />
            <PrivateRoute
              exact
              path="/notification/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/notification/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/inquiry" component={TheLayout} />
            <PrivateRoute
              exact
              path="/inquiry/answer/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/inquiry/send/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/favorite" component={TheLayout} />
            <PrivateRoute exact path="/favorite/create" component={TheLayout} />
            <PrivateRoute exact path="/favorite/:id" component={TheLayout} />

            <PrivateRoute exact path="/deep_link" component={TheLayout} />
            <PrivateRoute
              exact
              path="/deep_link/create"
              component={TheLayout}
            />
            <PrivateRoute exact path="/deep_link/:id" component={TheLayout} />

            <PrivateRoute exact path="/keys" component={TheLayout} />
            <PrivateRoute exact path="/keys/create" component={TheLayout} />
            <PrivateRoute exact path="/keys/:id" component={TheLayout} />
            <PrivateRoute exact path="/historykeys/:id" component={TheLayout} />

            <PrivateRoute exact path="/question_web" component={TheLayout} />
            <PrivateRoute
              exact
              path="/question_web/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/question_web/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/promotional-codes"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/promotional-codes/create"
              component={TheLayout}
            />

            <Route path="/login" name="Login" component={Login} />
          </Switch>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
