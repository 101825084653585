import moment from "moment";
import axiosClient from "./axiosClient";
import { convertParams } from "src/util/convertUtil";
// const HOSTNAME = process.env.REACT_APP_HOST_NAME;
// const HOSTNAME = "https://server.stepsy.app:4001/api/v1";
const HOSTNAME = process.env.REACT_APP_API;

const fields = 'fields=["$all"]';
const table = "user";

const memberApi = {
  getAll: (page, limit, filter) => {
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&filter=${JSON.stringify(
      filter
    )}&limit=${limit}&paranoid=false&order=[["created_at", "desc"]]`;
    console.log(url);
    return axiosClient.get(url);
  },
  getListNew: (params) => {
    return axiosClient.get(`${HOSTNAME}/${table}`, {
      params: convertParams(params),
    })
  },
  getCodeEnterpise: () => {
    const url = `${HOSTNAME}/auth/code`;
    return axiosClient.get(url);
  },

  getListDevice: (id) => {
    const url = `${HOSTNAME}/device?fields=["id","device_name"]&filter={"user_id":${JSON.stringify(
      id
    )}}`;
    return axiosClient.get(url);
  },

  filter: (userType, page, limit) => {
    const url = `${HOSTNAME}/${table}?${fields}&filter={"type_member": "${userType}"}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
    return axiosClient.get(url);
  },
  filterAll: (text, page, limit) => {
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&filter={"$or": [{"username":{"$iLike":"%25${text}%25"}},{"nickname":{"$iLike":"%25${text}%25"}},  {"login_type":{"$iLike":"%25${text}%25"}}, {"occupation":"${text}"}]}`;
    return axiosClient.get(url);
  },
  getList: () => {
    const url = `${HOSTNAME}/${table}?${fields}&limit=999`;
    return axiosClient.get(url);
  },

  findOne: (id, token) => {
    const url = `${HOSTNAME}/${table}/${id}?fields=["$all"]`;
    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  addUser: (body, token) => {
    const url = `${HOSTNAME}/${table}`;
    return axiosClient.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  register: (body, token) => {
    const url = `${HOSTNAME}/user`;
    return axiosClient.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  resetPassword : (body, token) => {
    const url = `${HOSTNAME}/user/change_password`;
    return axiosClient.put(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  editUser: (body, id, token) => {
    const url = `${HOSTNAME}/${table}/${id}`;
    return axiosClient.put(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteUser: (id, token) => {
    const url = `${HOSTNAME}/${table}/${id}`;
    return axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteDevice: (id, token) => {
    const url = `${HOSTNAME}/device/${id}`;
    return axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  uploadFile: (body, token) => {
    const url = `${HOSTNAME}/image/upload/`;
    return axiosClient.post(url, body, {
      headers: {
        "Content-Type": "image/png",
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default memberApi;
