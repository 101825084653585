import moment from "moment";
import userApi from "../api/userApi";

export function getLearningTime(learning_time) {
  var cd = 24 * 60 * 60 * 1000,
    ch = 60 * 60 * 1000,
    d = Math.floor(learning_time / cd),
    h = Math.floor((learning_time - d * cd) / ch),
    m = Math.round((learning_time - d * cd - h * ch) / 60000),
    pad = function (n) {
      return n < 10 ? "0" + n : n;
    };
  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }

  return `${d}일 ${pad(h)}시간 ${pad(m)}분`;
}

export function convertMillisecondsToHours(learning_time) {
  const ms = learning_time;
  let hours = (ms / (1000 * 60 * 60)).toFixed(2);
  return hours;
}

export const convertPurpose = (purpose) => {
  switch (purpose) {
    case "hobby":
      return "취미";
    case "study":
      return "학업";
    case "employment":
      return "취업";
    case "promotion":
      return "승진";
    case "task":
      return "업무";
    case "trip":
      return "여행";
    case "certicate":
      return "자격증";
    case "make_friends":
      return "친구사귀기";
    case "etc":
      return "기타";
    default:
      return "";
  }
};

export const convertBusinessPurpose = (purpose) => {
  switch (purpose) {
    case "CONFERENCE_CALL":
      return "컨퍼런스 콜";
    case "OVERSEA_STRIP":
      return "해외 출장 및 영업";
    case "PRESENTATION":
      return "프레젠테이션";
    case "COMMUNICATION":
      return "업무 관련 소통";
    case "LANGUAGE_EXAM":
      return "어학 시험";
    case "PROMOTION":
      return "승진";
    case "SELF_DEVELOPMENT":
      return "자기 개발";
    case "GLOBAL_MINDSET":
      return "글로벌 마인드 함양";
    case "EMPLOYMENT_JOB_CHANGE":
      return "취업 및 이직";
    case "ETC":
      return "기타";
    default:
      return "";
  }
};

export const convertBusinessOccupation = (occupation) => {
  switch (occupation) {
    case "PLANNING":
      return "기획, 전략";
    case "MARKETING":
      return "마케팅, 홍보";
    case "HR":
      return "인사, 노무, HRD";
    case "OFFICE_WORK":
      return "총무, 법무, 사무";
    case "IT":
      return "IT개발, 데이터";
    case "SALES":
      return "영업, 판매";
    case "MD":
      return "상품기획, MD";
    case "PRODUCTION":
      return "생산";
    case "MEDICAL_TREATMENT":
      return "의료";
    case "EDUCATION":
      return "교육";
    case "FINANCE":
      return "금융, 보험";
    case "ETC":
      return "기타";
    default:
      return "";
  }
};

export const convertBusinessTime = (time) => {
  switch (time) {
    case "MINS_15":
      return "하루 15분";
    case "MINS_30":
      return "하루 30분";
    case "HOUR_1":
      return "하루 1시간";
    case "HOUR_2":
      return "하루 2시간";
    case "MORE_THAN_HOUR_2":
      return "하루 2시간 이상";
    case "ETC":
      return "기타";
    default:
      return "";
  }
};

export function ageEngToKorean(age) {
  let ageConvert = "";

  switch (age) {
    case "0-10":
      ageConvert = "10대 미만";
      break;
    case "10":
      ageConvert = "10대";
      break;
    case "20":
      ageConvert = "20대";
      break;
    case "30":
      ageConvert = "30대";
      break;
    case "40":
      ageConvert = "40대";
      break;
    case "50":
      ageConvert = "50대";
      break;
    case "60":
      ageConvert = "60대";
      break;
    case "70":
      ageConvert = "70대 이상";
      break;
    default:
      ageConvert = "";
      break;
  }
  return ageConvert;
}

export function occupationEngToKorean(occupation) {
  let occupationKorean = "";

  switch (occupation) {
    case "pre_school":
      occupationKorean = "미취학";
      break;
    case "high_school":
      occupationKorean = "초중고등학생";
      break;
    case "college":
      occupationKorean = "대학생";
      break;
    case "job_prepare":
      occupationKorean = "취업준비생";
      break;
    case "office_worker":
      occupationKorean = "직장인";
      break;
    case "entrepreneur":
      occupationKorean = "사업가";
      break;
    case "freelancer":
      occupationKorean = "프리랜서";
      break;
    case "occupation":
      occupationKorean = "무직";
      break;
    case "etc":
      occupationKorean = "기타";
      break;
    default:
      occupationKorean = "";
      break;
  }
  return occupationKorean;
}

export function convertMili(millisec) {
  var seconds = (millisec / 1000).toFixed(0);
  var minutes = Math.floor(seconds / 60);
  var hours = "";

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    hours = hours >= 10 ? hours : "0" + hours;
    minutes = minutes - hours * 60;
    minutes = minutes >= 10 ? minutes : "0" + minutes;
  }

  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : "0" + seconds;
  if (hours !== "") {
    return hours + ":" + minutes + ":" + seconds;
  }
  return minutes + ":" + seconds;
}

export function convertLevel(totalScore) {
  // params totalScore là test_records
  let result = "";

  if (totalScore) {
    let scorePronounce = totalScore.pronounce.totalScore;
    let rhytmScore = totalScore.rhytmScore.totalScore;
    let intonationScore = totalScore.intonationScore.totalScore;
    let proficiencyScore = totalScore.proficiencyScore.totalScore;
    let total =
      scorePronounce + rhytmScore + intonationScore + proficiencyScore;

    if (total > 300) {
      return (result = "ADVANCED");
    } else if (total > 100) {
      return (result = "INTERMEDIATE");
    } else {
      return (result = "BEGINNER");
    }
  } else {
    return result;
  }
}

export async function convertNickName(id) {
  const res = await userApi.findOne(id);

  if (res.code === 200) {
    return res.results.object.username;
  }
}

export const formatSerial = (number) => {
  let string = number?.toString();

  if (string?.length === 1 && string !== "0") return `00${number}`;
  else if (string?.length === 2) return `0${number}`;
  else if (!string) return "";
  else if (string === "0") return "";
  else return number;
};

export const convertSerial = (pitch, pronunciation, rhythm, fluency) => {
  let pitchString = pitch?.toString();
  let pronunciationString = pronunciation?.toString();
  let rhythmString = rhythm?.toString();
  let fluencyString = fluency?.toString();

  if (pitchString?.length === 1) pitch = `00${pitch}`;
  else if (pitchString?.length === 2) pitch = `0${pitch}`;
  else if (pitchString?.length === 3) pitch = `${pitch}`;

  if (pronunciationString?.length === 1) pronunciation = `00${pronunciation}`;
  else if (pronunciationString?.length === 2)
    pronunciation = `0${pronunciation}`;
  else if (pronunciationString?.length === 3)
    pronunciation = `${pronunciation}`;

  if (rhythmString?.length === 1) rhythm = `00${rhythm}`;
  else if (rhythmString?.length === 2) rhythm = `0${rhythm}`;
  else if (rhythmString?.length === 3) rhythm = `${rhythm}`;

  if (fluencyString?.length === 1) fluency = `00${fluency}`;
  else if (fluencyString?.length === 2) fluency = `0${fluency}`;
  else if (fluencyString?.length === 3) fluency = `${fluency}`;

  if (!pitchString && !pronunciationString && !rhythmString && !fluencyString) {
    return "";
  }

  return `${pitch}-${pronunciation}-${rhythm}-${fluency}`;
};

export const checkTime = (time) => {
  let pattern = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;
  let res = time.match(pattern);
  if (res[0] === res?.input) return true;
  else return false;
  //   if (res[0] === time) return
};

export const updateSerial = (serialNumber) => {
  let serial = serialNumber?.toString();

  if (serial?.length === 1) serialNumber = `00${serialNumber}`;
  else if (serial?.length === 2) serialNumber = `0${serialNumber}`;
  else if (serial?.length === 3) serialNumber = `${serialNumber}`;

  return serialNumber;
};

export const convertUnix = (time) => {
  let temp = moment
    .unix(time / 1000)
    .tz("Asia/Seoul")
    .format("DD-MM-YYYY HH:mm");
  return temp;
};

export const converToTimeParse = (time) => {
  let timeSplit = time?.split(":");
  let timeParse = +timeSplit[0] * 60 * 60 + +timeSplit[1] * 60 + +timeSplit[2];
  return timeParse;
};

export const convertParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, JSON.stringify(value)])
  );
};

export const convertTimeZoneNotTimeStamp = (data) => {
  return data
    ? `${new Date(data).toISOString().substring(0, 10)} ${new Date(data)
        .toISOString()
        .substring(11, 16)} `
    : "";
};

export const convertTimeZone = (data) => {
  return data ? moment(+data).format("YYYY-MM-DD HH:mm") : "";
};

export const toHHMMSS = (item) => {
  if (!item) {
    return "00:00:00";
  } else {
    var sec_num = parseInt(item, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }
};

export const concatAndRemoveDuplicates = (arr1, arr2) => {
  let result = [...arr1, ...arr2];
  result = [...new Set(result)];
  return result;
};
