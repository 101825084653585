import { createSlice } from "@reduxjs/toolkit";

const question = createSlice({
  name: "question",
  initialState: {
    total_question: 0,
    questions: [
      // {
      //     question: 1,
      //      question_content: ...
      //     result: null
      // }
    ],
  },
  reducers: {
    save: (state, action) => {
      const check_exist = state.questions.filter((item) => {
        return item.question === action.payload.question;
      });
      if (check_exist.length) {
        const state_temp = state.questions.map((item) => {
          if (item.question === action.payload.question) {
            return {
              ...item,
              result: action.payload.result,
            };
          } else {
            return { ...item };
          }
        });
        state.questions = state_temp;
      } else {
        state.questions.push(action.payload);
      }
    },
    setTotalQuestion: (state, action) => {
      state.total_question = action.payload;
    },
  },
});

const { reducer, actions } = question;
export const { save, setTotalQuestion } = actions;
export default reducer;
