import axiosClient from "./axiosClient";
// const HOSTNAME = process.env.REACT_APP_HOST_NAME;
// const HOSTNAME = "https://server.stepsy.app:4001/api/v1";
const HOSTNAME = process.env.REACT_APP_API;

const fileds = 'fields=["$all"]';
const table = "employee";

const userApi = {
  getAll: () => {
    const url = `${HOSTNAME}/${table}?${fileds}&order=[["updated_at", "desc"]]`;
    return axiosClient.get(url);
  },

  findOne: (id, token) => {
    const url = `${HOSTNAME}/employee/${id}?fields=["$all"]`;
    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  addUser: (body, token) => {
    const url = `${HOSTNAME}/employee`;
    return axiosClient.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  editUser: (body, id, token) => {
    const url = `${HOSTNAME}/employee/${id}`;
    return axiosClient.put(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteUser: (id, token) => {
    const url = `${HOSTNAME}/employee/${id}`;
    return axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  uploadFile: (body, token) => {
    const url = `${HOSTNAME}/image/upload/`;
    return axiosClient.post(url, body, {
      headers: {
        "Content-Type": "image/png",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  post: (body) => {
    const url = `${HOSTNAME}/auth/employee_login`;
    return axiosClient.post(url, body);
  },
};

export default userApi;
